import { CemeteryList_cemeteries$data } from '@/components/CemeteryList/__generated__/CemeteryList_cemeteries.graphql'
import { CemeteryListItemProps } from '@lifedot/components/CemeteryListItem'
import { replacementImage } from '@lifedot/constants/images'
import { orderedSectionType, displayablePurchasePrices } from '@lifedot/utils'

type PriceKey =
  keyof CemeteryList_cemeteries$data['items'][number]['best_price_by_category']

export const convertDataToItem = (
  data: CemeteryList_cemeteries$data['items'][number],
  searchingStationId: undefined | null | number,
  filteringSectionTypes: undefined | null | string[]
): CemeteryListItemProps => {
  const {
    cemeteryId,
    name,
    kana,
    address,
    director_comment: directorComment,
    requestable
  } = data
  const reviewSummary = {
    score: data.review_summary.total_score,
    count: data.review_summary.count
  }
  const descriptions = data.descriptions
    .map(({ subject }) => subject)
    .filter((v): v is string => !!v)
  const featureNames = data.features.map(({ name }) => name)
  const cemeteryTypeName = data.cemetery_types.filter(
    ({ code }) => code !== 'not_koei'
  )[0]?.name
  const sectName = data.sects[0]?.name
  const searchingStation = searchingStationId
    ? data.stations.find(({ stationId }) => stationId === searchingStationId)
    : undefined
  const stationSupplement = searchingStation
    ? {
        distance: searchingStation.distance,
        name: searchingStation.name,
        isNearest: searchingStation.nearest
      }
    : null
  const nearestStation = !stationSupplement
    ? data.stations.find(({ nearest }) => nearest)
    : null
  const station = nearestStation
    ? {
        distance: nearestStation.distance,
        name: nearestStation.name,
        isNearest: nearestStation.nearest
      }
    : null
  const slideImages = data.main_image
    ? [data.main_image, ...data.sub_images].filter(
        (image): image is Exclude<typeof image, null> => !!image
      )
    : [replacementImage]
  const valuableReview = data.review_summary.valuable_review
    ? {
        ...data.review_summary.valuable_review,
        text: data.review_summary.valuable_review.answers.find(
          ({ code }) => code === 'facilities'
        )?.text
      }
    : null

  const prices = orderedSectionType([...data.section_types])
    .filter(({ code }) =>
      filteringSectionTypes ? filteringSectionTypes.includes(code) : true
    )
    .map(({ code, name }) => ({
      category: code,
      cemeteryTypeName: name,
      ...displayablePurchasePrices(
        data.best_price_by_category[code as PriceKey]
      )
    }))

  return {
    cemeteryId,
    name,
    kana,
    address,
    reviewSummary,
    descriptions,
    directorComment,
    featureNames,
    cemeteryTypeName,
    sectName,
    station,
    stationSupplement,
    slideImages,
    valuableReview,
    prices,
    requestable
  }
}

import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { Accordion } from '@lifedot/atoms/Accordion'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import { mq } from '@lifedot/styles/mediaQuery'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  accordion: css({
    width: '80%',
    border: `1px solid ${palette.gray3}`,
    borderRadius: 50,
    color: palette.gray6,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 'auto',
    padding: 12,
    '[open] &': {
      backgroundColor: palette.gray1
    },
    [mq('sp')]: {
      padding: 8
    }
  }),
  openText: css({
    '[open] &': {
      display: 'none'
    }
  }),
  closeText: css({
    display: 'none',
    '[open] &': {
      display: 'block'
    }
  }),
  accordionIcon: css({
    verticalAlign: -3,
    [mq('sp')]: {
      verticalAlign: -4
    }
  })
}

type MoreInfoAccordionProps = {
  children: ReactNode
  id: number
}

export const MoreInfoAccordion: FC<MoreInfoAccordionProps> = ({
  children,
  id
}) => (
  <Accordion
    title={
      <EventTracker
        label={`recommend_${id}`}
        action="click"
        elementCategory={elementCategories.cemeteryCard}
        elementLabel={`recommend_${id}`}
      >
        <p css={[typography.textS, styles.accordion]}>
          <span css={styles.openText}>
            コメント・口コミを見る
            <AddBoxOutlinedIcon
              style={{ fontSize: 16 }}
              css={styles.accordionIcon}
            />
          </span>
          <span css={styles.closeText}>
            コメント・口コミを閉じる
            <IndeterminateCheckBoxOutlinedIcon
              style={{ fontSize: 16 }}
              css={styles.accordionIcon}
            />
          </span>
        </p>
      </EventTracker>
    }
  >
    {children}
  </Accordion>
)

import { FC } from 'react'
import { css } from '@emotion/react'
import { router } from '@lifedot/router'
import { typography } from '@lifedot/styles/typography'
import Link from 'next/link'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const titleStyles = {
  root: css({
    textDecoration: 'none',
    marginRight: 12
  }),
  name: css({
    textDecoration: 'underline'
  }),
  rubi: css({
    display: 'block',
    fontWeight: 'normal',
    lineHeight: 1
  })
}

interface CemeteryTitleProps {
  name: string
  kana: string | null
  id: number
  trackable: boolean
}

export const CemeteryTitle: FC<CemeteryTitleProps> = ({
  name,
  kana,
  id,
  trackable
}) => (
  <Link href={router.cemetery(id)} passHref prefetch={false}>
    <a css={titleStyles.root}>
      <EventTracker
        disabled={!trackable}
        label={`CemeteryListItem_${id}`}
        action="click"
        elementCategory={elementCategories.cemeteryCard}
        elementLabel={`detail_${id}`}
      >
        <span css={[typography.textXS, titleStyles.rubi]}>{kana}</span>
        <h2 css={[typography.textXL, titleStyles.name]}>{name}</h2>
      </EventTracker>
    </a>
  </Link>
)

import { FC } from 'react'
import { css } from '@emotion/react'
import { CemeteryPriceTable } from './CemeteryPriceTable'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { CemeteryAccess } from './CemeteryAccess'
import { Features } from './Features'
import { CemeteryAttributes } from './CemeteryAttributes'
import { Images } from './Images'
import { CemeteryTitle } from './CemeteryTitle'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { MoreInfoAccordion } from './MoreInfoAccordion'
import { FeaturePoints } from './FeaturePoints'
import { Divider } from '@lifedot/atoms/Divider'
import { DirectorComment } from './DirectorComment'
import { Review } from './Review'
import { CemeteryLink } from './CemeteryLink'
import { StockCheckBox } from './StockCheckBox'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  root: css({
    position: 'relative',
    backgroundColor: palette.white,
    border: `solid ${palette.gray3}`,
    borderWidth: '2px 1px'
  }),
  header: css({
    position: 'relative',
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    borderBottom: `1px solid ${palette.gray2}`
  }),
  checkBox: css({
    marginLeft: 'auto'
  }),
  content: css({
    display: 'flex',
    alignItems: 'flex-start',
    padding: 8,
    [mq('sp')]: {
      flexDirection: 'column',
      padding: 0
    }
  }),
  information: css({
    display: 'grid',
    gap: 8,
    marginLeft: 8,
    width: '100%',
    [mq('sp')]: {
      padding: 8,
      marginLeft: 0
    }
  }),
  points: css({
    backgroundColor: palette.background,
    padding: 12
  }),
  reviews: css({
    marginTop: 8
  })
}

export interface CemeteryListItemProps {
  imagePriority?: boolean
  moreInfo?: boolean
  cemeteryId: number
  name: string
  kana: string | null
  address: string | null
  reviewSummary: {
    score: number | null
    count: number
  }
  descriptions: string[]
  directorComment: string | null
  station?: {
    distance: number
    name: string
    isNearest: boolean
  } | null
  stationSupplement?: {
    distance: number
    name: string
  } | null
  featureNames: string[]
  cemeteryTypeName?: string
  sectName?: string
  slideImages: {
    path: string | null
    alt: string | null
  }[]
  valuableReview: {
    age: number
    gender: string
    text?: string | null
  } | null
  prices: {
    category: string
    cemeteryTypeName: string
    priceNumber: string
    priceUnit: string
    priceSupplement: string
    managementFee: string
  }[]
  trackable?: boolean
  requestable: boolean
}

export const CemeteryListItem: FC<CemeteryListItemProps> = ({
  cemeteryId,
  name,
  kana,
  address,
  reviewSummary,
  descriptions,
  directorComment,
  station,
  stationSupplement,
  featureNames,
  cemeteryTypeName,
  sectName,
  slideImages,
  valuableReview,
  prices,
  imagePriority,
  trackable = false,
  moreInfo,
  requestable
}) => {
  return (
    <article css={styles.root}>
      <div css={styles.header}>
        <CemeteryTitle name={name} kana={kana} id={cemeteryId} trackable />
        <ChevronRightIcon fontSize="small" />
        {requestable && (
          <div css={styles.checkBox}>
            <StockCheckBox id={cemeteryId} />
          </div>
        )}
      </div>
      <div css={styles.content}>
        <Images
          id={cemeteryId}
          images={slideImages}
          name={name}
          priority={imagePriority}
          trackable
        />
        <div css={styles.information}>
          <CemeteryAccess
            id={cemeteryId}
            address={address}
            station={station}
            stationSupplement={stationSupplement}
          />
          <Features labels={featureNames} />
          <CemeteryAttributes
            cemeteryTypeName={cemeteryTypeName}
            sectName={sectName}
          />
          {descriptions.length > 0 && (
            <div css={styles.points}>
              <FeaturePoints points={descriptions} />
            </div>
          )}
          {moreInfo && (
            <MoreInfoAccordion id={cemeteryId}>
              <div css={[styles.points, styles.reviews]}>
                {!!directorComment && (
                  <>
                    <DirectorComment
                      comment={directorComment}
                      id={cemeteryId}
                    />
                    <Divider />
                  </>
                )}
                <Review
                  review={valuableReview}
                  id={cemeteryId}
                  summary={reviewSummary}
                />
              </div>
            </MoreInfoAccordion>
          )}
        </div>
      </div>
      <EventTracker
        disabled={!trackable}
        label={`CemeteryPriceTable_${cemeteryId}`}
        action="click"
        elementCategory={elementCategories.cemeteryCard}
        elementLabel={`price_${cemeteryId}`}
      >
        <CemeteryPriceTable cemeteryId={cemeteryId} prices={prices} />
      </EventTracker>
      <EventTracker
        disabled={!trackable}
        label={`CemeteryListItem_${cemeteryId}`}
        action="click"
        elementCategory={elementCategories.cemeteryCard}
        elementLabel={`detail_${cemeteryId}`}
      >
        <CemeteryLink id={cemeteryId} />
      </EventTracker>
    </article>
  )
}

import { FC } from 'react'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import TrainIcon from '@material-ui/icons/Train'
import RoomIcon from '@material-ui/icons/Room'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { distanceFormatter } from '@lifedot/utils'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const accessStyles = {
  icon: css({
    color: palette.gray5,
    verticalAlign: -4
  }),
  address: css({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  }),
  linkText: css({
    color: palette.main03,
    marginLeft: 16,
    display: 'inline-flex',
    alignItems: 'center'
  })
}
interface CemeteryAccessProps {
  address: string | null
  id: number
  station?: {
    distance: number
    name: string
    isNearest: boolean
  } | null
  stationSupplement?: {
    distance: number
    name: string
  } | null
}

export const CemeteryAccess: FC<CemeteryAccessProps> = ({
  address,
  id,
  station,
  stationSupplement
}) => (
  <List>
    {(station || stationSupplement) && (
      <ListItem icon={<TrainIcon fontSize="small" css={accessStyles.icon} />}>
        <div>
          {stationSupplement && (
            <p css={typography.textM}>
              {`${stationSupplement.name}駅から${distanceFormatter(
                stationSupplement.distance
              )}`}
            </p>
          )}
          {station && (
            <p css={typography.textM}>
              {station.isNearest ? '最寄駅：' : ''}
              <b>{station.name}駅</b>
              <small>({distanceFormatter(station.distance)})</small>
            </p>
          )}
        </div>
      </ListItem>
    )}
    <ListItem icon={<RoomIcon fontSize="small" css={accessStyles.icon} />}>
      <div css={accessStyles.address}>
        <p>
          <b css={typography.textM}>{address}</b>
        </p>
        <EventTracker
          label={`access_${id}`}
          action="click"
          elementCategory={elementCategories.cemeteryCard}
          elementLabel={`access_${id}`}
        >
          <Link href={router.access(id)} passHref prefetch={false}>
            <a css={[typography.textS, accessStyles.linkText]} rel="nofollow">
              アクセスを見る
              <ChevronRightIcon fontSize="small" />
            </a>
          </Link>
        </EventTracker>
      </div>
    </ListItem>
  </List>
)

import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import PhoneIcon from '@material-ui/icons/Phone'
import {
  lifedotCallCenterNumber,
  lifedotDirectNumber
} from '@lifedot/constants/phoneNumbers'

const styles = {
  wrap: css({
    textAlign: 'center'
  }),
  anchor: css({
    color: palette.accent02,
    display: 'inline-flex',
    alignItems: 'center'
  }),
  note: css({
    color: palette.gray6
  })
}

interface TelephoneNumberProps {
  large?: boolean
  showNote?: boolean
  direct?: boolean
}

export const TelephoneNumber: FC<TelephoneNumberProps> = ({
  large = false,
  showNote = false,
  direct = false
}) => {
  const telNumber = direct ? lifedotDirectNumber : lifedotCallCenterNumber
  return (
    <div css={styles.wrap}>
      <a
        href={`tel:${telNumber}`}
        css={[styles.anchor, large ? typography.headingL : typography.headingS]}
      >
        <PhoneIcon />
        {telNumber}
      </a>
      {showNote && (
        <p css={[styles.note, large ? typography.textS : typography.textXS]}>
          通話料無料 | 10:00〜19:00受付
        </p>
      )}
    </div>
  )
}

import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const attributesStyles = {
  root: css({
    color: palette.gray6
  })
}
interface CemeteryAttributesProps {
  cemeteryTypeName?: string
  sectName?: string
}

export const CemeteryAttributes: FC<CemeteryAttributesProps> = ({
  cemeteryTypeName,
  sectName
}) => (
  <div css={[typography.textS, attributesStyles.root]}>
    墓地タイプ：<b>{cemeteryTypeName ?? '-'}</b> / 宗旨宗派：
    <b>{sectName ?? '-'}</b>
  </div>
)

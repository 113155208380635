import { FC } from 'react'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import CheckIcon from '@material-ui/icons/Check'
import { Section } from './Section'

const style = {
  check: css({
    color: palette.main,
    verticalAlign: 'middle'
  })
}

const icon = <CheckIcon fontSize="small" css={style.check} />

interface FeaturePointsProps {
  points: string[]
}
export const FeaturePoints: FC<FeaturePointsProps> = ({ points }) => (
  <Section title="おすすめポイント">
    <List icon={icon}>
      {points.map((point) => (
        <ListItem key={point}>
          <b css={typography.textL}>{point}</b>
        </ListItem>
      ))}
    </List>
  </Section>
)

import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  title: css({
    color: palette.main03,
    fontWeight: 'bold'
  }),
  section: css({
    ':not(:first-of-type)': {
      marginTop: 12
    },
    ':not(:last-of-type)': {
      marginBottom: 12
    }
  })
}

interface SectionProps {
  children: ReactNode
  title: string
}

export const Section: FC<SectionProps> = ({ title, children }) => (
  <div css={styles.section}>
    <p css={[typography.textS, styles.title]}>{title}</p>
    {children}
  </div>
)

import { FC } from 'react'
import { css } from '@emotion/react'
import { router, urlify } from '@lifedot/router'
import { Rating } from '@lifedot/atoms/Rating'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Link from 'next/link'
import SmsIcon from '@material-ui/icons/Sms'

const reviewSummaryStyles = {
  root: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.gray6,
    borderRadius: 4,
    backgroundColor: palette.gray1,
    padding: '2px 8px'
  }),
  count: css({
    color: palette.main03
  }),
  countWrap: css({
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center'
  })
}

interface ReviewSummaryProps {
  id: number
  score: number
  count: number
}

export const ReviewSummary: FC<ReviewSummaryProps> = ({ id, score, count }) => {
  return (
    <div css={reviewSummaryStyles.root}>
      <b css={typography.textS}>みんなの評価</b>
      <Rating point={score ?? 0} />
      <div css={reviewSummaryStyles.countWrap}>
        <SmsIcon style={{ fontSize: 16 }} />
        <b css={typography.textS}>口コミ</b>
        <Link
          href={`${urlify(router.review(id, count))}`}
          passHref
          prefetch={false}
        >
          <a css={[typography.textS, reviewSummaryStyles.count]} rel="nofollow">
            {count}件
          </a>
        </Link>
      </div>
    </div>
  )
}

import { FC, useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { useBookmark } from '@lifedot/hooks/use-bookmark'
import { palette } from '@lifedot/styles/palette'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  checkbox: css({
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 28,
    height: 28,
    flexShrink: 0,
    border: `4px solid ${palette.gray4}`,
    borderRadius: 4,
    boxSizing: 'content-box',
    ':before': {
      content: '""',
      position: 'absolute',
      left: 7,
      top: -1,
      width: 10,
      height: 20,
      borderRight: `4px solid ${palette.gray2}`,
      borderBottom: `4px solid ${palette.gray2}`,
      transform: 'rotate(45deg)'
    }
  }),
  input: css({
    opacity: 0,
    position: 'absolute',
    left: 0,
    ':checked': {
      '~ span': {
        ':before': {
          borderRight: `5px solid ${palette.accent}`,
          borderBottom: `5px solid ${palette.accent}`
        }
      }
    },
    ':focus-visible': {
      '~ span': {
        outline: `2px auto -webkit-focus-ring-color`,
        borderRadius: 2
      }
    }
  }),
  label: css({
    cursor: 'pointer',
    display: 'block'
  })
}
interface StockCheckBoxProps {
  id: number
}

export const StockCheckBox: FC<StockCheckBoxProps> = ({ id }) => {
  const [, helper] = useBookmark()
  const handleBookmark = useCallback(() => {
    if (helper.isBookmark(id)) helper.removeBookmark(id)
    else helper.addBookmark(id)
  }, [helper, id])

  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    if (window) setIsClient(true)
  }, [])

  return (
    <EventTracker
      label={`check_${id}`}
      action="checked"
      elementCategory={elementCategories.stockList}
      elementLabel={`check_${id}`}
    >
      <label css={styles.label} aria-label="検討リストに追加・まとめて資料請求">
        <input
          type="checkbox"
          onChange={handleBookmark}
          checked={isClient && helper.isBookmark(id)}
          css={styles.input}
        />
        <span css={styles.checkbox} />
      </label>
    </EventTracker>
  )
}

import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { ageDecadeFormatter, genderI18n } from '@lifedot/utils'
import Link from 'next/link'
import { router } from '@lifedot/router'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { mq } from '@lifedot/styles/mediaQuery'
import { ReviewSummary } from './ReviewSummary'
import { Section } from './Section'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  readMoreIcon: css({
    verticalAlign: -5,
    [mq('sp')]: {
      verticalAlign: -6
    }
  }),
  readMore: css({
    display: 'block',
    color: palette.main03,
    textAlign: 'right'
  }),
  reviewer: css({
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray4}`,
    color: palette.gray6,
    padding: 2,
    marginRight: 8
  }),
  summary: css({
    '>div': {
      background: 'none',
      padding: 0
    }
  })
}

interface ReviewProps {
  id: number
  review: {
    age: number
    gender: string
    text?: string | null
  } | null
  summary: {
    score: number | null
    count: number
  }
}
export const Review: FC<ReviewProps> = ({
  id,
  review,
  summary: { score, count }
}) => (
  <Section title="口コミ評価">
    <div css={styles.summary}>
      {score !== null && <ReviewSummary id={id} score={score} count={count} />}
    </div>
    {review ? (
      <>
        <p css={typography.textS}>
          <span css={[typography.textXS, styles.reviewer]}>
            {ageDecadeFormatter(review.age)}
            {genderI18n(review.gender)}
          </span>
          {review.text}
        </p>
        <EventTracker
          label={`review_${id}`}
          action="click"
          elementCategory={elementCategories.cemeteryCard}
          elementLabel={`review_${id}`}
        >
          <Link href={router.review(id, count)} passHref prefetch={false}>
            <a css={[typography.textS, styles.readMore]}>
              口コミの続きを読む
              <ChevronRightIcon fontSize="small" css={styles.readMoreIcon} />
            </a>
          </Link>
        </EventTracker>
      </>
    ) : (
      <p css={typography.textS}>この霊園はまだ誰からも評価されていません。</p>
    )}
  </Section>
)

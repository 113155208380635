import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import Link from 'next/link'
import { router } from '@lifedot/router'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const linksStyles = {
  cta: css({
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    color: palette.main03,
    fontWeight: 'bold',
    display: 'block',
    padding: '16px 0'
  }),
  chevron: css({
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    right: 8
  })
}

interface CemeteryLinkProps {
  id: number
}

export const CemeteryLink: FC<CemeteryLinkProps> = ({ id }) => {
  return (
    <Link href={router.cemetery(id)} passHref prefetch={false}>
      <a css={[linksStyles.cta, typography.textM]} rel="nofollow">
        お墓の詳細を見る
        <ChevronRightIcon fontSize="small" css={linksStyles.chevron} />
      </a>
    </Link>
  )
}

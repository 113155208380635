import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Link from 'next/link'
import Image from 'next/image'
import { router } from '@lifedot/router'

const styles = {
  row: css({
    display: 'table-row',
    textDecoration: 'none',
    borderBottom: `1px solid ${palette.gray2}`
  }),
  cell: css({
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '8px 4px',
    color: palette.gray6,
    [mq('sp')]: {
      padding: '8px 4px'
    }
  }),
  price: css({
    color: palette.alert
  }),
  more: css({
    color: palette.main03,
    padding: 0
  }),
  icon: css({
    verticalAlign: -7,
    [mq('sp')]: {
      color: palette.gray3
    }
  }),
  type: css({
    fontSize: 0
  }),
  pc: css({
    [mq('sp')]: {
      display: 'none'
    }
  })
}

interface CemeteryPriceTableRowProps {
  category: string
  cemeteryId: number
  cemeteryTypeName: string
  priceNumber: string
  priceUnit: string
  priceSupplement: string
  managementFee: string
  linkNofollow?: boolean
}

export const CemeteryPriceTableRow: FC<CemeteryPriceTableRowProps> = ({
  category,
  cemeteryId,
  cemeteryTypeName,
  priceNumber,
  priceUnit,
  priceSupplement,
  managementFee,
  linkNofollow
}) => {
  return (
    <Link
      href={router.price(cemeteryId) + `#${category}-detail`}
      passHref
      prefetch={false}
    >
      <a css={styles.row} rel={linkNofollow ? 'nofollow' : undefined}>
        <div css={styles.cell}>
          <div css={styles.type}>
            <Image
              src={images[category] ?? ''}
              width={20}
              height={20}
              alt={cemeteryTypeName}
            />
          </div>
          <b css={typography.textM}>{cemeteryTypeName}</b>
        </div>
        <div css={[styles.cell, styles.price]}>
          <b css={typography.textL}>{priceNumber}</b>
          <span css={typography.textS}>
            {priceUnit}
            {priceSupplement}
          </span>
        </div>
        <div css={[typography.textS, styles.cell]}>{managementFee}</div>
        <div css={[typography.textS, styles.cell, styles.more]}>
          <span css={styles.pc}>詳細を見る</span>
          <ChevronRightIcon css={styles.icon} />
        </div>
      </a>
    </Link>
  )
}

const images: Record<string, string> = {
  nokotsu:
    'https://s3.lifedot.jp/uploads/ending/path/67882/type_icon_nokotsu.png',
  jumoku:
    'https://s3.lifedot.jp/uploads/ending/path/67885/type_icon_jumoku.png',
  eitai_kuyo:
    'https://s3.lifedot.jp/uploads/ending/path/67883/type_icon_eitai_kuyo.png',
  normal: 'https://s3.lifedot.jp/uploads/ending/path/67884/type_icon_normal.png'
}

import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Image from 'next/image'
import { getDirector } from '@lifedot/utils'
import Link from 'next/link'
import { router } from '@lifedot/router'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { mq } from '@lifedot/styles/mediaQuery'
import { Section } from './Section'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  readMoreIcon: css({
    verticalAlign: -5,
    [mq('sp')]: {
      verticalAlign: -6
    }
  }),
  readMore: css({
    display: 'block',
    color: palette.main03,
    textAlign: 'right'
  }),
  directorCom: css({
    display: 'flex'
  }),
  director: css({
    width: 44,
    flexShrink: 0,
    color: palette.gray6,
    fontWeight: 'bold',
    textAlign: 'center'
  }),
  comment: css({
    marginLeft: 12,
    alignSelf: 'flex-start',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical'
  })
}

interface DirectorCommentProps {
  comment: string
  id: number
}

export const DirectorComment: FC<DirectorCommentProps> = ({ comment, id }) => (
  <Section title="お墓ディレクターのコメント">
    <div css={styles.directorCom}>
      <div css={styles.director}>
        <Image
          src={getDirector(id).iconImagePath}
          width={44}
          height={44}
          alt="お墓ディレクターの写真"
        />
        <p css={typography.textS}>{getDirector(id).name}</p>
      </div>
      <p css={[typography.textS, styles.comment]}>{comment}</p>
    </div>
    <EventTracker
      label={`comment_${id}`}
      action="click"
      elementCategory={elementCategories.cemeteryCard}
      elementLabel={`comment_${id}`}
    >
      <Link
        href={`${router.cemetery(id)}#a-introduction`}
        passHref
        prefetch={false}
      >
        <a css={[typography.textS, styles.readMore]} rel="nofollow">
          コメントの続きを読む
          <ChevronRightIcon fontSize="small" css={styles.readMoreIcon} />
        </a>
      </Link>
    </EventTracker>
  </Section>
)

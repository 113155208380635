import { FC } from 'react'
import { css } from '@emotion/react'
import { Label } from '@lifedot/atoms/Label'

const featureStyles = {
  root: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4
  })
}

interface FeaturesProps {
  labels: string[]
}

export const Features: FC<FeaturesProps> = ({ labels }) => (
  <div css={featureStyles.root}>
    {labels.map((label) => (
      <Label large emphasis key={label}>
        {label}
      </Label>
    ))}
  </div>
)

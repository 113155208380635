import { FC, useCallback, useState } from 'react'
import { css } from '@emotion/react'
import { router } from '@lifedot/router'
import { mq } from '@lifedot/styles/mediaQuery'
import Link from 'next/link'
import Image from 'next/image'
import { EventTracker } from '@lifedot/tracking'
import { ResponsiveContents } from '../ResponsiveContents'

const styles = {
  imgWrap: css({
    flexShrink: 0,
    display: 'flex'
  }),
  img: css({
    height: 171,
    overflow: 'hidden',
    '&:only-of-type': {
      img: {
        transform: 'translateY(-15%)'
      }
    }
  }),
  thumbnails: css({
    flexShrink: 0,
    marginLeft: 1,
    [mq('sp')]: {
      width: '20%'
    }
  }),
  side: css({
    fontSize: 0,
    ':not(:first-of-type)': {
      marginTop: 1
    }
  }),
  slider: css({
    height: 172,
    display: 'flex',
    overflowX: 'scroll'
  }),
  sliderImage: css({
    width: '80%',
    flexShrink: 0,
    overflow: 'hidden',
    img: {
      transform: 'translateY(-10%)'
    },
    '&:only-of-type': {
      width: '100%',
      img: {
        transform: 'translateY(-20%)'
      }
    },
    '&:not(:first-of-type)': {
      marginLeft: 1
    }
  })
}

interface ImagesProps {
  images: {
    path: string | null
    alt: string | null
  }[]
  name: string
  id: number
  priority?: boolean
  trackable: boolean
}

export const Images: FC<ImagesProps> = ({
  images,
  name,
  id,
  priority = false,
  trackable
}) => {
  const [mainImage, setMainImage] = useState<{ src: string; alt: string }>({
    src: images[0]?.path ?? '',
    alt: images[0]?.alt || name
  })
  const handleMouseOver = useCallback(
    (image: ImagesProps['images'][0]) => () => {
      setMainImage({ src: image.path ?? '', alt: image.alt || name })
    },
    [name]
  )
  return (
    <>
      <ResponsiveContents
        desktopContents={
          <div css={styles.imgWrap}>
            <div css={styles.img}>
              <Link href={router.cemetery(id)} passHref prefetch={false}>
                <a rel="nofollow">
                  <EventTracker
                    disabled={!trackable}
                    label={`CemeteryListItem_${id}`}
                    action="click"
                  >
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <Image
                      {...mainImage}
                      priority={priority}
                      width={540}
                      height={405}
                    />
                  </EventTracker>
                </a>
              </Link>
            </div>
            {images.length > 1 && (
              <div css={styles.thumbnails}>
                {images.slice(0, 4).map((v) => (
                  <div css={styles.side} key={v.path}>
                    <Image
                      src={v.path ?? ''}
                      onMouseOver={handleMouseOver(v)}
                      alt={v.alt || name}
                      quality={5}
                      width={70}
                      height={42}
                      objectFit="cover"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        }
        mobileContents={
          <div css={styles.slider}>
            {images.slice(0, 4).map((v) => (
              <Link
                href={router.cemetery(id)}
                passHref
                prefetch={false}
                key={v.path}
              >
                <a css={styles.sliderImage} rel="nofollow">
                  <EventTracker
                    disabled={!trackable}
                    label={`CemeteryListItem_${id}`}
                    action="click"
                  >
                    <Image
                      src={v.path ?? ''}
                      alt={v.alt || name}
                      priority={priority}
                      width={540}
                      height={405}
                    />
                  </EventTracker>
                </a>
              </Link>
            ))}
          </div>
        }
      />
    </>
  )
}

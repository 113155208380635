import { FC } from 'react'
import { css } from '@emotion/react'
import { CemeteryPriceTableRow } from './CemeteryPriceTableRow'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  table: css({
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse'
  }),
  row: css({
    display: 'table-row',
    backgroundColor: palette.gray2,
    color: palette.gray6
  }),
  cell: css({
    display: 'table-cell',
    padding: 4,
    textAlign: 'center'
  })
}

interface CemeteryPriceTableProps {
  cemeteryId: number
  prices: {
    category: string
    cemeteryTypeName: string
    priceNumber: string
    priceUnit: string
    priceSupplement: string
    managementFee: string
  }[]
}

export const CemeteryPriceTable: FC<CemeteryPriceTableProps> = ({
  cemeteryId,
  prices
}) => (
  <div css={styles.table}>
    <div css={[typography.textS, styles.row]}>
      <p css={styles.cell}>お墓タイプ</p>
      <p css={styles.cell}>参考価格</p>
      <p css={styles.cell}>管理費</p>
      <p css={styles.cell}></p>
    </div>

    {prices.map((price, index) => (
      <CemeteryPriceTableRow
        cemeteryId={cemeteryId}
        {...price}
        key={price.category}
        linkNofollow={index !== 0}
      />
    ))}
  </div>
)
